
import { getPropertyConstructionCaseQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import ConstructionCases from "../components/ConstructionCases.vue";

export default {
  title() {
    return `${this.$t("MENU_CONSTRUCTION_CASES")} ${this.$store.getters["search/searchTerm"]}`;
  },

  apollo: {
    property: getPropertyConstructionCaseQuery,
  },

  components: {
    ConstructionCases,
  },

  computed: {
    propertyMapped: function () {
      if (this.property.condo?.property != null) {
        return this.property.condo?.property;
      } else {
        return this.property;
      }
    },
    loading: function () {
      return this.property == null || this.$apollo.queries.property.loading;
    },
  },
};

import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.regexp.constructor.js";
export default {
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      searchTerm: "",
      sortDescending: true,
      headers: [{
        name: this.$t("BUILD_PROJ"),
        active: false,
        sort: this.sortByTitle
      }, {
        name: this.$t("BUILD_PROJ_YEAR"),
        active: true,
        sort: this.sortByYear
      }, {
        name: this.$t("BUILD_PROJ_TITLE_REGISTER"),
        active: false,
        sort: this.sortByCadastral
      }, {
        name: this.$t("BUILDING_PROJ_CASE"),
        active: false,
        sort: this.sortByCaseNumber
      }, {
        name: this.$t("BUILD_PROJ_STATUS"),
        active: false,
        sort: this.sortByStatus
      }]
    };
  },
  daSearch: new Intl.Collator("da", {
    numeric: true,
    sensitivity: "base"
  }),
  supportedMunicipalityCodes: [147, 151, 201, 230, 259, 336, 400, 480, 707, 710, 791, 810],
  computed: {
    sortFunction: function sortFunction() {
      return this.headers.find(function (header) {
        return header.active;
      }).sort;
    },
    hasConstructionCases: function hasConstructionCases() {
      var _this$property$plots;
      return ((_this$property$plots = this.property.plots) === null || _this$property$plots === void 0 ? void 0 : _this$property$plots.flatMap(function (plot) {
        return plot.constructionCases;
      }).filter(function (cs) {
        return cs != null;
      }).length) > 0;
    },
    hasAccessToConstructionCases: function hasAccessToConstructionCases() {
      var _this$property$plots2,
        _this = this;
      return (_this$property$plots2 = this.property.plots) === null || _this$property$plots2 === void 0 ? void 0 : _this$property$plots2.some(function (plot) {
        return _this.$options.supportedMunicipalityCodes.some(function (code) {
          return code == plot.municipalityCode;
        });
      });
    },
    constructionCases: function constructionCases() {
      var _this$property$plots3,
        _this2 = this;
      var filteredAndSortedConstructionCases = (_this$property$plots3 = this.property.plots) === null || _this$property$plots3 === void 0 || (_this$property$plots3 = _this$property$plots3.flatMap(function (plot) {
        var _plot$constructionCas;
        return (_plot$constructionCas = plot.constructionCases) === null || _plot$constructionCas === void 0 ? void 0 : _plot$constructionCas.map(function (cs) {
          return {
            matrikelNumber: plot.matrikelNumber,
            ownersGuildName: plot.ownersGuildName,
            "case": cs
          };
        });
      })) === null || _this$property$plots3 === void 0 || (_this$property$plots3 = _this$property$plots3.filter(function (cs) {
        return cs != null;
      })) === null || _this$property$plots3 === void 0 || (_this$property$plots3 = _this$property$plots3.filter(function (cs) {
        var _cs$case, _cs$case2;
        return ((_cs$case = cs["case"]) === null || _cs$case === void 0 || (_cs$case = _cs$case.title) === null || _cs$case === void 0 ? void 0 : _cs$case.match(new RegExp(_this2.searchTerm, "i"))) || ((_cs$case2 = cs["case"]) === null || _cs$case2 === void 0 || (_cs$case2 = _cs$case2.caseNumber) === null || _cs$case2 === void 0 ? void 0 : _cs$case2.match(new RegExp(_this2.searchTerm, "i")));
      })) === null || _this$property$plots3 === void 0 ? void 0 : _this$property$plots3.sort(this.sortFunction);
      if (this.sortDescending) {
        filteredAndSortedConstructionCases.reverse();
      }
      return filteredAndSortedConstructionCases;
    }
  },
  // See https://public.filarkiv.dk/

  methods: {
    getPublicAccessFiles: function getPublicAccessFiles(constructionCase) {
      return constructionCase.documents.flatMap(function (document) {
        return document.files;
      }).filter(function (file) {
        return file.publicAccess;
      });
    },
    hasRestrictedAccess: function hasRestrictedAccess(constructionCase) {
      return constructionCase.documents.flatMap(function (document) {
        return document.files;
      }).some(function (file) {
        return !file.publicAccess;
      });
    },
    numberOfRestrictedFiles: function numberOfRestrictedFiles(constructionCase) {
      return constructionCase.documents.flatMap(function (document) {
        return document.files;
      }).filter(function (file) {
        return !file.publicAccess;
      }).length;
    },
    getCadastralString: function getCadastralString(constructionCase) {
      return "".concat(constructionCase.matrikelNumber, " ").concat(constructionCase.ownersGuildName);
    },
    selectSorting: function selectSorting(header) {
      if (header.active) {
        this.sortDescending = !this.sortDescending;
      } else {
        this.headers.forEach(function (h) {
          return h.active = h == header;
        });
      }
    },
    sortByTitle: function sortByTitle(a, b) {
      return this.$options.daSearch.compare(a["case"].title, b["case"].title);
    },
    sortByYear: function sortByYear(a, b) {
      return a["case"].year - b["case"].year;
    },
    sortByCadastral: function sortByCadastral(a, b) {
      return this.$options.daSearch.compare(this.getCadastralString(a), this.getCadastralString(b));
    },
    sortByCaseNumber: function sortByCaseNumber(a, b) {
      return this.$options.daSearch.compare(a["case"].caseNumber, b["case"].caseNumber);
    },
    sortByStatus: function sortByStatus(a, b) {
      return a["case"].closed === b["case"].closed ? 0 : a["case"].closed ? 1 : -1;
    }
  }
};